<template>
  <b-overlay class="overlayfull" :show="isLoading">
    <iframe class="fullscreen" :src="src" frameborder="0"></iframe>
  </b-overlay>
</template>

<script>
import {BOverlay} from 'bootstrap-vue'
export default {
  components:{
    BOverlay
  },
  data(){
    return {
      src:'',
      isLoading: false
    }
  },
  methods:{
    async getData(){
      const appLoading = document.getElementById('loading-bg')
      appLoading.style.display = ''
      try {
        const params = this.$route.query
        const get = await this.$http.get('v1/reports/rkp_pnj',{params:params})
        const {data} = await this.$http.post(`${process.env.VUE_APP_REPORT_URL}/production/api/renders/rkp_pnj_pdf`,get.data)
        this.src = data
      } catch (error) {
        this.handleError(error)

      }
      appLoading.style.display = 'none'
      this.isLoading = false
    }
  },
  async created(){
    await this.getData()
  }
}
</script>

<style class="scss">
  .fullscreen {
    position:fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    width:100%;
    height:100%;
    border:none;
    margin:0;
    padding:0;
    overflow:hidden;
    z-index:999999;
  }
</style>